import React, {Component} from 'react';
import authService from './api-authorization/AuthorizeService'
import {
    Alert,
    Button,
    Form,
    FormGroup,
    Input,
    Label,
    Container,
    Row,
    Col,
    Table,
    Modal,
    ModalBody,
    ModalHeader
} from 'reactstrap';
import accountService from "./services/AccountService";
import handpieceService from "./services/HandpieceService";
import {connect} from "react-redux";
import {withAITracking} from "@microsoft/applicationinsights-react-js";
import {reactPlugin} from "../AppInsights";

const pageSize = 5;
const requestSuccessfulMessagePost = "Handpiece repair request has been successful. Please post to us via the pre-paid envelope as soon as possible";
const requestSuccessfulMessageCourier = "Your courier request has been successful. Please make sure items are cleaned and packaged ready " +
    "for the couriers arrival.";

class RequestCourier extends Component {
    static displayName = "Request Courier";

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            advanced: false,
            submitButtonDisabled: false,
            requestSuccessful: false,
            requestErrored: false,
            handpieces: [],
            filteredHandpieces: [],
            selectedNewHandpieces: [],
            searchedHandpieces: [],
            filterText: "",
            pageNumber: 1,
            totalPages: 0,
            newHandpieceModalOpen: false,
            newHandpieceMake: "",
            newHandpieceModel: "",
            newHandpieceSerial: "",
            newHandpieceSubmitEnabled: false,
            printFormLoading: true,
            printFormDataLoading: true,
            additionalInformation: "",
            numberOfHandpieces: 1,
            messagesSent: 0,
            requestCourier: true
        };
        this.focusRef = React.createRef();
    }

    componentDidMount() {
        this.populateAccountInfo();
        window.addEventListener('message', this.handleMessage);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handleMessage);
    }

    next() {
        this.setState({
            pageNumber: this.state.pageNumber + 1
        });
    }

    prev() {
        this.setState({
            pageNumber: this.state.pageNumber - 1
        });
    }

    onChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    };

    onNumberOfHandpiecesChange = (e) => {
        this.setState({
            numberOfHandpieces: e.target.value
        });
        this.sendSelectedHandpieceData(e.target.value);
    };

    sendSelectedHandpieceData(numberOfHandpieces) {
        const iframe = this.getIFrame('repair-form-print');
        const iframeWindow = iframe.contentWindow || iframe;
        if (this.state.advanced) {
            iframeWindow.postMessage({
                action: 'repair-print-form-data',
                selectedHandpieces: this.state.handpieces.filter(h => h.selected),
                selectedNewHandpieces: this.state.selectedNewHandpieces
            });
        } else {
            const blankHandpiece = {make: '', model: '', serialNumber: ''};
            const handpieces = [];
            for (let i = 0; i < numberOfHandpieces; i++) {
                handpieces.push(blankHandpiece)
            }
            iframeWindow.postMessage({
                printWindowLoading: true,
                action: 'repair-print-form-data',
                selectedHandpieces: handpieces,
                selectedNewHandpieces: []
            });
        }
    }

    toggleNewHandpieceModalOpen() {
        if (!this.state.newHandpieceModalOpen) {
            this.setState({
                newHandpieceMake: "",
                newHandpieceModel: "",
                newHandpieceSerial: "",
                newHandpieceSubmitEnabled: false,
                searchedHandpieces: []
            })
        }
        this.setState({
            newHandpieceModalOpen: !this.state.newHandpieceModalOpen
        });
    }

    toggleRequestModalOpen = () => {
        if (!this.state.requestModalOpen) {
            this.sendSelectedHandpieceData();
            this.setState({
                requestModalOpen: true,
                printFormDataLoading: true,
                repairFormPrinted: false,
                requestCourier: true,
                requestModalCloseText: "Cancel"
            });
        } else {
            this.setState({
                requestModalOpen: !this.state.requestModalOpen,
            });
        }
    };

    setRequestCourier(value) {
        this.setState({
            requestCourier: value
        })
    }

    filterHandpieces(inputHandpieces, text) {
        let handpieces = inputHandpieces.filter(f => f.lastRepairRequestStatus === 7 && !f.selected);
        if (text !== '') {
            handpieces = handpieces.filter(f => ((f.make + ' ' + f.model + ' ' + f.serialNumber).toUpperCase()).indexOf(text) > -1)
        }
        return handpieces;
    }

    filterSearchEvent(e) {
        const text = e.target.value.toUpperCase();
        const filteredHandpieces = this.filterHandpieces(this.state.handpieces, text);
        this.setState({
            filteredHandpieces: filteredHandpieces,
            filterText: text,
            pageNumber: 1,
            totalPages: Math.ceil(filteredHandpieces.length / pageSize)
        });
    }

    canSaveNewHandpiece(make, model, serial, filteredHandpieces) {
        var textEntered = false;
        if (make && model && serial) {
            textEntered = true;
        }
        console.log("Is text entered: " + textEntered);
        console.log("Any handpieces?: " + (filteredHandpieces.length === 0));
        console.log("Combined: " + (textEntered && filteredHandpieces.length === 0));
        return (textEntered && filteredHandpieces.length === 0);
    }

    handpieceFilter(handpiece, make, model, serial) {
        if (handpiece.selected) return false;

        if (make) {
            if (!handpiece.make.toLowerCase().includes(make.toLowerCase())) return false;
        }
        if (model) {
            if (!handpiece.model.toLowerCase().includes(model.toLowerCase())) return false;
        }
        if (serial) {
            if (!handpiece.serialNumber.toLowerCase().includes(serial.toLowerCase())) return false;
        }
        return true;
    }

    searchHandpieceFilter(make, model, serial) {
        if (!make && !model && !serial) return [];
        return this.state.handpieces.filter(f => this.handpieceFilter(f, make, model, serial));
    }

    onMakeChange(e) {
        var filteredHandpieces = this.searchHandpieceFilter(e.target.value, this.state.newHandpieceModel, this.state.newHandpieceSerial);
        this.setState({
            newHandpieceMake: e.target.value,
            newHandpieceSubmitEnabled: this.canSaveNewHandpiece(e.target.value, this.state.newHandpieceModel, this.state.newHandpieceSerial, filteredHandpieces),
            searchedHandpieces: filteredHandpieces
        });
    }

    onModelChange(e) {
        var filteredHandpieces = this.searchHandpieceFilter(this.state.newHandpieceMake, e.target.value, this.state.newHandpieceSerial);
        this.setState({
            newHandpieceModel: e.target.value,
            newHandpieceSubmitEnabled: this.canSaveNewHandpiece(this.state.newHandpieceMake, e.target.value, this.state.newHandpieceSerial, filteredHandpieces),
            searchedHandpieces: filteredHandpieces
        });
    }

    onSerialChange(e) {
        var filteredHandpieces = this.searchHandpieceFilter(this.state.newHandpieceMake, this.state.newHandpieceModel, e.target.value);
        this.setState({
            newHandpieceSerial: e.target.value,
            newHandpieceSubmitEnabled: this.canSaveNewHandpiece(this.state.newHandpieceMake, this.state.newHandpieceModel, e.target.value, filteredHandpieces),
            searchedHandpieces: filteredHandpieces
        });
    }

    saveNewHandpiece() {
        var newHandpieces = this.state.selectedNewHandpieces.concat({
            id: this.state.selectedNewHandpieces.length,
            make: this.state.newHandpieceMake,
            model: this.state.newHandpieceModel,
            serialNumber: this.state.newHandpieceSerial
        });
        this.setState({
            selectedNewHandpieces: newHandpieces,
            newHandpieceModalOpen: false
        });
    }

    removeNewHandpiece(key) {
        this.setState({
            selectedNewHandpieces: this.state.selectedNewHandpieces.slice(key - 1, key)
        });
    }

    chooseHandpiece(e, handpieceId) {
        const handpieces = this.state.handpieces.map(h => handpieceId === h.id ? Object.assign({}, h, {selected: true}) : h);
        this.setState({
            handpieces: handpieces,
            filteredHandpieces: this.filterHandpieces(handpieces, this.state.filterText.toUpperCase())
        });
    }

    chooseHandpieceModal(e, handpieceId) {
        this.chooseHandpiece(e, handpieceId);
        this.toggleNewHandpieceModalOpen();
    }

    removeHandpiece(e, handpieceId) {
        const handpieces = this.state.handpieces.map(h => handpieceId === h.id ? Object.assign({}, h, {selected: false}) : h);
        this.setState({
            handpieces: handpieces,
            filteredHandpieces: this.filterHandpieces(handpieces, this.state.filterText.toUpperCase())
        });
    }

    handleMessage = (event) => {
        if (event.data.action === 'repair-print-form-loaded') {
            this.setState({
                printFormLoading: false
            });
            this.sendSelectedHandpieceData(1);
        }
        if (event.data.action === 'repair-print-form-loaded-2') {
            this.setState({
                printFormDataLoading: false
                // messagesSent: this.state.messagesSent - 1,
                // printFormLoading: this.state.messagesSent - 1 > 0
            });
        }
    };

    getIFrame(id) {
        return document.frames
            ? document.frames[id]
            : document.getElementById(id);
    }

    printIframe = (id, e) => {
        e && e.preventDefault();
        const iframe = this.getIFrame(id);
        const iframeWindow = iframe.contentWindow || iframe;

        iframe.focus();
        iframeWindow.print();

        this.setState({
            repairFormPrinted: true
        });

        return false;
    };

    renderHandpieceFilter() {
        return (
            <Col lg={6}>
                <Row>
                    <Col>
                        <Table>
                            <colgroup>
                                <col span="1" style={{width: "50%"}}/>
                                <col span="1" style={{width: "25%"}}/>
                                <col span="1" style={{width: "25%"}}/>
                            </colgroup>
                            <thead>
                            <tr>
                                <td colSpan="1" style={{verticalAlign: "middle", fontWeight: "bold"}}>Choose
                                    Handpieces:
                                </td>
                                <td colSpan="2">
                                    <Input className="float-left" id="handpiece-filter"
                                           onChange={(text) => this.filterSearchEvent(text)}
                                           placeholder="Filter"
                                           value={this.state.filterText}/>
                                </td>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.filteredHandpieces.slice((this.state.pageNumber - 1) * pageSize, this.state.pageNumber * pageSize).map(handpiece =>
                                <tr key={handpiece.id}>
                                    <td colSpan="2">{handpiece.make + ' ' + handpiece.model}<br/>S/N: {handpiece.serialNumber}{handpiece.lastBookedIn &&
                                        <br/>}{handpiece.lastBookedIn && "Last Repair: " + Intl.DateTimeFormat("en-GB").format(Date.parse(handpiece.lastBookedIn))}
                                    </td>
                                    <td className="button-cell align-middle"><Button color="link" size="sm"
                                                                                     disabled={this.state.submitButtonDisabled}
                                                                                     onClick={e => this.chooseHandpiece(e, handpiece.id)}>Select</Button>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                {this.state.totalPages > 1 &&
                    <Row>
                        <Col className="ml-2">
                            <Button disabled={this.state.pageNumber === 1} onClick={() => this.prev()}>Prev</Button>
                        </Col>
                        <Col className="text-center">
                            Page {this.state.pageNumber} of {this.state.totalPages}
                        </Col>
                        <Col className="mr-2" style={{textAlign: "right"}}>
                            <Button disabled={this.state.totalPages === this.state.pageNumber}
                                    onClick={() => this.next()}>Next</Button>
                        </Col>
                    </Row>
                }
            </Col>
        );
    }

    renderSelectedHandpieces() {
        return (
            <Col lg={6}>
                {this.state.handpieces.filter(h => h.selected).length + this.state.selectedNewHandpieces.length > 0 &&
                    <Row className="mt-lg-0 mt-3">
                        <Col>
                            <Table>
                                <colgroup>
                                    <col span="1" style={{width: "75%"}}/>
                                    <col span="1" style={{width: "25%"}}/>
                                </colgroup>
                                <thead>
                                <tr>
                                    <td colSpan="2" style={{verticalAlign: "middle", fontWeight: "bold"}}>Handpieces to
                                        repair ({this.state.handpieces.filter(h => h.selected).length}):
                                    </td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.handpieces.filter(h => h.selected).map(handpiece =>
                                    <tr key={handpiece.id}>
                                        <td>{handpiece.make + ' ' + handpiece.model}<br/>{handpiece.serialNumber}{handpiece.lastBookedIn &&
                                            <br/>}{handpiece.lastBookedIn && "Last Repair: " + Intl.DateTimeFormat("en-GB").format(Date.parse(handpiece.lastBookedIn))}
                                        </td>
                                        <td className="button-cell align-middle"><Button color="link" size="sm"
                                                                                         disabled={this.state.submitButtonDisabled}
                                                                                         onClick={e => this.removeHandpiece(e, handpiece.id)}>Remove</Button>
                                        </td>
                                    </tr>
                                )}
                                {this.state.selectedNewHandpieces.map(handpiece =>
                                    <tr key={handpiece.id}>
                                        <td>{handpiece.make + ' ' + handpiece.model}<br/>{handpiece.serialNumber}</td>
                                        <td className="button-cell align-middle"><Button color="link" size="sm"
                                                                                         disabled={this.state.submitButtonDisabled}
                                                                                         onClick={e => this.removeNewHandpiece(handpiece.id)}>Remove</Button>
                                        </td>
                                    </tr>
                                )}

                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                }

                {this.state.handpieces.filter(h => h.selected).length + this.state.selectedNewHandpieces.length > 0 &&
                    <Row className="mt-lg-0 mt-3">
                        <Col xs={12}>
                            <Button color="primary"
                                    disabled={this.state.submitButtonDisabled || this.state.printFormLoading}
                                    onClick={() => this.toggleRequestModalOpen()}>
                                {this.state.printFormLoading && <>Loading...</>}
                                {!this.state.printFormLoading && <>Request</>}
                            </Button>
                        </Col>
                    </Row>
                }
            </Col>
        );
    }

    renderRequestModal() {
        return (
            <Modal isOpen={this.state.requestModalOpen} autoFocus={false} centered={true}
                   toggle={() => this.toggleRequestModalOpen()}>
                <ModalHeader>Repair Handpieces</ModalHeader>
                <ModalBody style={{height: "400px"}}>
                    {(this.state.printFormLoading || this.state.printFormDataLoading) &&
                        <div>Loading..</div>
                    }

                    {!(this.state.printFormLoading || this.state.printFormDataLoading) &&
                        <>
                            <Row><Col>Please print out the following repair form to accompany your
                                handpieces:</Col></Row>
                            <Row className="mt-2">
                                <Col xs={12}>
                                    <Button color="primary" disabled={this.state.printFormLoading}
                                            onClick={() => this.printIframe('repair-form-print')}>
                                        Print Repair Form
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    }

                    {this.state.repairFormPrinted &&
                        <Form onSubmit={this.requestCourierAdvanced}>
                            <Row className="mt-4"><Col>Please select a delivery method:</Col></Row>
                            <Row className="mt-2"><Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="radio" name="radio1" checked={this.state.requestCourier}
                                               onClick={() => this.setRequestCourier(true)}/>{' '}
                                        I'd like to request a courier
                                    </Label>
                                </FormGroup>

                                <FormGroup check>
                                    <Label check>
                                        <Input type="radio" name="radio1" checked={!this.state.requestCourier}
                                               onClick={() => this.setRequestCourier(false)}/>{' '}
                                        I have a pre-paid envelope
                                    </Label>
                                </FormGroup>
                            </Col></Row>

                            <Row className="mt-2">
                                {this.state.requestErrored &&
                                    <Col xs={12}><Alert color="danger">{this.state.errorMessage}</Alert></Col>
                                }
                                {this.state.requestSuccessful && this.state.requestCourier &&
                                    <Col xs={12}><Alert color="success">{requestSuccessfulMessageCourier}</Alert></Col>
                                }
                                {this.state.requestSuccessful && !this.state.requestCourier &&
                                    <Col xs={12}><Alert color="success">{requestSuccessfulMessagePost}</Alert></Col>
                                }
                                <Col xs={6}>
                                    <Button color="primary" disabled={this.state.submitButtonDisabled}>Request</Button>
                                </Col>
                                <Col xs={6} className="text-right">
                                    <Button color="secondary"
                                            onClick={() => this.toggleRequestModalOpen()}>{this.state.requestModalCloseText}</Button>
                                </Col>
                            </Row>
                        </Form>
                    }
                </ModalBody>
            </Modal>
        );
    }

    renderModal() {
        return (
            <Modal isOpen={this.state.newHandpieceModalOpen} autoFocus={false} centered={true}
                   toggle={() => this.toggleNewHandpieceModalOpen()} size="lg">
                <ModalHeader>Add New Handpiece</ModalHeader>
                <ModalBody>
                    <Row className="mb-2">
                        <Col lg={4} style={{height: "200px"}}>
                            <Form>
                                <FormGroup row className="ml-l">
                                    <Col>
                                        <Input type="text" autoFocus={true} placeholder="Make:" maxLength={100}
                                               onChange={(e) => this.onMakeChange(e)}
                                               value={this.state.newHandpieceMake}/>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="ml-l">
                                    <Col>
                                        <Input type="text" placeholder="Model:" maxLength={100}
                                               onChange={(e) => this.onModelChange(e)}
                                               value={this.state.newHandpieceModel}/>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="ml-l">
                                    <Col>
                                        <Input type="text" placeholder="Serial Number:" maxLength={100}
                                               onChange={(e) => this.onSerialChange(e)}
                                               value={this.state.newHandpieceSerial}/>
                                    </Col>
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col style={{overflowY: "scroll", height: "200px"}}>
                            {this.state.searchedHandpieces.map((handpiece, i) =>
                                <Row key={i}>
                                    <Col xs={10} style={{lineHeight: "36px"}}>
                                        {handpiece.make} {handpiece.model} {handpiece.serialNumber}
                                    </Col>
                                    <Col xs={2}>
                                        <Button color="link"
                                                onClick={e => this.chooseHandpieceModal(e, handpiece.id)}>
                                            Select
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                    <Row className="ml-l">
                        <Col xs={6}>
                            <Button color="primary" disabled={!this.state.newHandpieceSubmitEnabled} onClick={() => this.saveNewHandpiece()}>Add</Button>
                        </Col>
                        <Col xs={6} className="text-right">
                            <Button color="secondary" onClick={() => this.toggleNewHandpieceModalOpen()}>Cancel</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        )
    }

    renderAdvanced() {
        return (
            <div>
                <Row>
                    {this.renderHandpieceFilter()}
                    {this.renderSelectedHandpieces()}
                </Row>
                <Row className="mt-3 mb-3 ml-1 no-gutters">
                    <Col className="no-gutters">
                        <Button color="primary" disabled={this.state.submitButtonDisabled}
                                onClick={() => this.toggleNewHandpieceModalOpen()}>Add New Handpiece</Button>
                    </Col>
                </Row>
                {this.renderModal()}
                {this.renderRequestModal()}
            </div>
        );
    }

    renderSimple() {
        const buttonEnabled = this.state.additionalInformation && this.state.numberOfHandpieces && !this.state.submitButtonDisabled && !this.state.printFormLoading;
        return (
            <Form onSubmit={this.handleSubmit}>
                <Row><Col>
                    <p>Please fill in the following details to request a courier pick up.</p>
                </Col></Row>
                <FormGroup row>
                    <Col>
                        <Label for="numberOfHandpieces">Number of items:</Label>
                        <Input type="number" name="numberOfHandpieces" id="numberOfHandpieces"
                               value={this.state.numberOfHandpieces}
                               onChange={(e) => this.onNumberOfHandpiecesChange(e)} min="1" max="99"/>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col>
                        <Label for="additionalInformation">Additional Information:</Label>
                        <Input type="text" name="additionalInformation" id="additionalInformation"
                               value={this.state.additionalInformation} onChange={(e) => this.onChange(e)}/>
                    </Col>
                </FormGroup>
                <Row><Col>
                    <Button color="primary" disabled={!buttonEnabled}
                            onClick={(e) => this.printIframe("repair-form-print", e)}>{this.state.printFormLoading ? "Loading" : "Print Repair Form"}</Button>
                </Col></Row>
                {this.state.repairFormPrinted &&
                    <>
                        <Row className="mt-4"><Col>Please select a delivery method:</Col></Row>
                        <Row className="mt-2"><Col>
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="radio1" checked={this.state.requestCourier}
                                           onChange={() => this.setRequestCourier(true)}/>{' '}
                                    I'd like to request a courier
                                </Label>
                            </FormGroup>

                            <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="radio1" checked={!this.state.requestCourier}
                                           onChange={() => this.setRequestCourier(false)}/>{' '}
                                    I have a pre-paid envelope
                                </Label>
                            </FormGroup>
                        </Col></Row>
                        <Row className="mt-2"><Col>
                            {this.state.requestErrored &&
                                <Alert color="danger">{this.state.errorMessage}</Alert>
                            }
                            {this.state.requestSuccessful &&
                                <Alert color="primary">{requestSuccessfulMessageCourier}</Alert>
                            }
                        </Col></Row>
                        <Row className="mt-2"><Col>
                            <Button color="primary" disabled={!buttonEnabled}>Submit</Button>
                        </Col></Row>
                    </>
                }

            </Form>
        );
    }

    renderLoading() {
        return (
            <Row>
                <Col>
                    <p>Loading...</p>
                </Col>
            </Row>
        );
    }

    renderBasedOnMode() {
        if (this.state.loading) {
            return this.renderLoading();
        }
        if (this.state.advanced) {
            return this.renderAdvanced(this.state);
        }
        return this.renderSimple(this.state);
    }

    render() {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <h1>Repair Handpieces</h1>
                    </Col>
                </Row>
                {this.renderBasedOnMode(this.state)}

                <iframe id="repair-form-print"
                        title="Print Repair Form"
                        style={{display: 'none'}}
                        src="/repair-print-form"/>

            </Container>
        );
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const data = JSON.stringify({
            numberOfHandpieces: this.state.numberOfHandpieces,
            AdditionalInformation: this.state.additionalInformation.toUpperCase(),
            requestCourier: this.state.requestCourier
        });
        const token = await authService.getAccessToken();
        this.setState({submitButtonDisabled: true});
        const response = await fetch('api/courier', {
            method: 'POST',
            body: data,
            headers: !token ? {} : {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.ok) {
            this.setState({requestErrored: false, errorMessage: '', requestSuccessful: true});
        } else {
            const data = await response.json();
            this.setState({requestErrored: true, errorMessage: data.message, submitButtonDisabled: false});
        }
    };

    requestCourierAdvanced = async (event) => {
        event.preventDefault();
        this.setState({submitButtonDisabled: true});
        const data = JSON.stringify({
            handpieces: this.state.handpieces.filter(h => h.selected).map(h => h.id),
            customerHandpieces: this.state.selectedNewHandpieces,
            requestCourier: this.state.requestCourier
        });
        const token = await authService.getAccessToken();
        const response = await fetch("api/courier/advanced", {
            method: 'POST',
            body: data,
            headers: !token ? {} : {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.ok) {
            this.setState({
                requestErrored: false,
                errorMessage: '',
                requestSuccessful: true,
                requestModalCloseText: "Close"
            });
        } else {
            const data = await response.json();
            this.setState({requestErrored: true, errorMessage: data.message, submitButtonDisabled: false});
        }
    };

    async populateAccountInfo() {
        const accountInfo = await accountService.getAccountInfo();
        if (!accountInfo.advanced) {
            this.setState({advanced: accountInfo.advanced, loading: false});
            return;
        }
        let handpieces = await handpieceService.getHandpieces();
        handpieces = handpieces.map(h => Object.assign({}, h, {selected: false})).sort((a, b) => a.make + ' ' + a.model + ' ' + a.serialNumber > b.make + ' ' + b.model + ' ' + b.serialNumber);
        this.setState({
            advanced: accountInfo.advanced,
            handpieces: handpieces,
            filteredHandpieces: this.filterHandpieces(handpieces, ""),
            filterText: "",
            loading: false,
            totalPages: Math.ceil(handpieces.length / 10),
            pageNumber: 1
        });
    }
}

export default connect()(withAITracking(reactPlugin, RequestCourier));