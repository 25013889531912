let companyInfo;

export class CompanyInfoService {
    async getCompanyInfo() {
        if (companyInfo == null) {
            companyInfo = (await fetch('api/companyinfo')).json();
        }
        return companyInfo;
    }
}

const companyInfoService = new CompanyInfoService();

export default companyInfoService;