import React, {Component} from 'react';
import authService from './api-authorization/AuthorizeService'
import {Col, Row} from "reactstrap";
import {withAITracking} from "@microsoft/applicationinsights-react-js";
import {reactPlugin} from "../AppInsights";

class Documents extends Component {
    static displayName = "Documents";

    constructor(props) {
        super (props);
        this.state = {
            documents: []
        };
    }

    componentDidMount() {
        this.populateDocuments();
    }

    async populateDocuments() {
        const token = await authService.getAccessToken();
        const response = await fetch('api/documents', {
            headers: !token ? {} : {'Authorization': `Bearer ${token}`}
        });
        const data = await response.json();
        this.setState({documents: data});
    }

    render() {
        return (
            <div>
                <h1>Downloadable Documents</h1>
                <Row>
                {this.state.documents.map(doc =>
                    <Col xs={12}>
                        <a href={"/api/documents/" + doc.id}>{doc.description}</a>
                    </Col>
                )}
                </Row>
            </div>
        );
    }
}

export default withAITracking(reactPlugin, Documents);