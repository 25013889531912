import React, {Component} from 'react';
import {Collapse, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink} from 'reactstrap';
import {Link} from 'react-router-dom';
import {LoginMenu} from './api-authorization/LoginMenu';
import './NavMenu.css';
import {withAITracking} from "@microsoft/applicationinsights-react-js";
import {reactPlugin} from "../AppInsights";

class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            handpieceDropdownOpen: false,
            reportsDropdownOpen: false
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    toggleDropdown = () => {
        this.setState({
            handpieceDropdownOpen: !this.state.handpieceDropdownOpen
        })
    };

    toggleReportsDropdown = () => {
        this.setState({
            reportsDropdownOpen: !this.state.reportsDropdownOpen
        })
    };
    render() {
        return (
            <header className="no-print">
                <Navbar className="navbar-expand-lg navbar-toggleable-md ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2"/>
                        <NavbarBrand tag={Link} to="/"><img className="logo-img" height="100px" src="/images/mc-repairs-logo.png" alt="MC Repairs"/></NavbarBrand>
                        <Collapse className="d-lg-inline-flex flex-lg-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem className="d-block d-xl-none">
                                    <NavLink tag={Link} to="/invoices">Invoices</NavLink>
                                </NavItem>
                                <Dropdown nav className="d-block d-xl-none" inNavbar={true} isOpen={this.state.handpieceDropdownOpen} toggle={this.toggleDropdown}>
                                    <DropdownToggle nav caret>
                                       Handpieces
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem tag={Link} to="/handpieces">All</DropdownItem>
                                        <DropdownItem divider/>
                                        <DropdownItem tag={Link} to="/handpieces/status/1">Awaiting Courier</DropdownItem>
                                        <DropdownItem tag={Link} to="/handpieces/status/2">In Transit</DropdownItem>
                                        <DropdownItem tag={Link} to="/handpieces/status/3">In Assessment</DropdownItem>
                                        <DropdownItem tag={Link} to="/handpieces/status/4">Waiting for Approval</DropdownItem>
                                        <DropdownItem tag={Link} to="/handpieces/status/5">In Repair</DropdownItem>
                                        <DropdownItem tag={Link} to="/handpieces/status/6">Awaiting Supplier</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <NavItem className="d-block d-xl-none">
                                    <NavLink tag={Link} to="/requestcourier">Repair Handpieces</NavLink>
                                </NavItem>
                                <Dropdown nav className="d-block d-xl-none" inNavbar={true} isOpen={this.state.reportsDropdownOpen} toggle={this.toggleReportsDropdown}>
                                    <DropdownToggle nav caret>
                                        Reports
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem tag={Link} to="/reports/handpiece-history">Handpiece History</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <NavItem className="d-block d-xl-none">
                                    <NavLink tag={Link} to="/documents">Documents</NavLink>
                                </NavItem>
                                <NavItem className="d-block d-xl-none">
                                    <NavLink tag={Link} to="/account">Account</NavLink>
                                </NavItem>
                                <LoginMenu/>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}

export default withAITracking(reactPlugin, NavMenu);