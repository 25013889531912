import authService from "../api-authorization/AuthorizeService";
import querystring from "query-string";

export const statusColours = ['transparent', 'yellow', 'silver', 'lightpink', 'orange', 'plum', 'lightskyblue', 'lightgreen'];

export class HandpieceService {
    async getHandpieces(requestStatus) {
        const token = await authService.getAccessToken();
        let queryParams = {};
        if (requestStatus) {
            queryParams = { requestStatus: requestStatus}
        }
        const response = await fetch('api/handpieces?' + querystring.stringify(queryParams), {
            headers: !token ? {} : {'Authorization': `Bearer ${token}`}
        });
        return await response.json();
    }

    async getHandpieceDetail(id) {
        const token = await authService.getAccessToken();
        const response = await fetch('api/handpieces/' + id, {
            headers: !token ? {} : {'Authorization': `Bearer ${token}`}
        });
        return await response.json();
    }

    async acceptQuote(id) {
        const token = await authService.getAccessToken();
        const response = await fetch('api/handpieces/' + id + '/acceptquote', {
            method: 'POST',
            headers: !token ? {} : {'Authorization': `Bearer ${token}`}
        });
        if (response.ok) {
            return '';
        } else {
            const data = await response.json();
            return data.message;
        }
    }

    async getCountAwaitingQuote() {
        const token = await authService.getAccessToken();
        const response = await fetch('api/handpieces/awaitingquote', {
            headers: !token ? {} : {'Authorization': `Bearer ${token}`}
        });
        if (response.ok) {
            return (await response.json()).count;
        }
        return 0;
    }
}

const handpieceService = new HandpieceService();

export default handpieceService;
