import React, {Component} from 'react';
import {Button, Form, FormGroup, Label, Input, Alert} from 'reactstrap';
import querystring from 'query-string';
import authService from "./AuthorizeService";

export class Register extends Component {
    static displayName = "Registration";

    constructor(props) {
        super(props);
        this.state = {
            token: querystring.parse(props.location.search).token,
            errorMessage: '',
            tokenErrored: false,
            registrationErrored: false,
            registerButtonDisabled: false,
            loading: true,
            password: '',
            passwordConfirmation: ''
        };
    }

    componentDidMount() {
        this.checkToken(this.state.token);
    }

    onPasswordChange = (e) => {
        this.setState({
            password: e.target.value
        });
    };

    onPasswordConfirmationChange = (e) => {
        this.setState({
            passwordConfirmation: e.target.value
        });
    };

    render() {
        if (this.state.loading) {
            return(
                <div>
                    <h1>Complete Registration</h1>

                    <p>Loading...</p>
                </div>
            )
        }
        if (this.state.tokenErrored) {
            return(
                <div>
                    <h1>Complete Registration</h1>

                    <Alert color="danger">{this.state.errorMessage}. Please contact MC Repairs for more information</Alert>
                </div>
            )
        }

        return (
            <div>
                <h1>Complete Registration</h1>

                <p>Create a password for your account to complete your registration</p>

                <Form onSubmit={this.handleSubmit}>
                    <Input type="hidden" value={this.state.token} name="token"/>
                    <FormGroup>
                        <Label for="password">Password:</Label>
                        <Input type="password" name="password" id="password" onChange={(e) => this.onPasswordChange(e)} value={this.state.password}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="password-confirmation">Confirm password:</Label>
                        <Input type="password" name="password-confirmation" id="password-confirmation" onChange={(e) => this.onPasswordConfirmationChange(e)} value={this.state.passwordConfirmation}/>
                    </FormGroup>
                    <Label className="mb-3">Email Preferences (can be changed later):</Label>
                    <FormGroup className="ml-4">
                        <Input type="checkbox" name="sendquoteemails" id="send-quote-emails" defaultChecked={true}/>
                        <Label for="send-quote-emails">Send me progress updates on my handpiece repairs</Label>
                    </FormGroup>
                    <FormGroup className="ml-4">
                        <Input type="checkbox" name="sendinvoiceemails" id="send-invoice-emails" defaultChecked={true}/>
                        <Label for="send-invoice-emails">Send me copies of repair invoices by email</Label>
                    </FormGroup>
                    <FormGroup className="ml-4">
                        <Input type="checkbox" name="sendofferemails" id="send-offer-emails" defaultChecked={true}/>
                        <Label for="send-offer-emails">Keep me updated with offers and updates</Label>
                    </FormGroup>
                    {this.state.registrationErrored &&
                        <Alert color="danger">{this.state.errorMessage}</Alert>
                    }
                    <Button color="primary" disabled={this.state.registerButtonDisabled}>Register</Button>
                </Form>
            </div>
        );
    }

    handleSubmit = async (event) =>  {
        event.preventDefault();
        if (this.state.password !== this.state.passwordConfirmation) {
            this.setState({registrationErrored: true, errorMessage: 'Passwords must match'});
            return;
        }

        this.setState({registerButtonDisabled: true});
        const response = await fetch('api/registration/createuser', {
            method: 'POST',
            body: new FormData(event.target)
        });

        if (response.ok) {
            this.setState({registrationErrored: false, errorMessage: ''});
            this.props.history.push("login")
        } else {
            const data = await response.json();
            this.setState({registrationErrored: true, errorMessage: data.message, registerButtonDisabled: false});
        }
    };

    checkToken = async (token) => {
        const authenticated = await authService.isAuthenticated();
        if (authenticated) {
            this.setState({ tokenErrored: true, errorMessage: 'You are already logged in', loading: false})
            return;
        }
        const queryParams = { token: token };
        const response = await fetch('api/registration/verifytoken?'+ querystring.stringify(queryParams));

        if (response.ok) {
            this.setState({ tokenErrored: false, errorMessage: '', loading: false})
        } else {
            const data = await response.json();
            this.setState({tokenErrored: true, errorMessage: data.message, loading: false});
        }
    };
}
