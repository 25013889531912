import React, {Component} from 'react';
import {Table, Container, Row, Col} from 'reactstrap';
import authService from './api-authorization/AuthorizeService'
import {withAITracking} from "@microsoft/applicationinsights-react-js";
import {reactPlugin, appInsights} from "../AppInsights";
import {SeverityLevel} from '@microsoft/applicationinsights-web';

class Invoice extends Component {
    static displayName = "Invoice History";

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        this.populateInvoiceInfo();
    }

    renderInvoice(invoice) {
        let pdfLink = "/api/invoices/pdf/" + invoice.pdfLink;
        return (
            <Container fluid>
                <Row><Col xs={3} className="invoice-heading">Invoice Number</Col><Col xs={3}>{invoice.invoiceNumber}</Col></Row>
                <Row><Col xs={3} className="invoice-heading">Date</Col><Col xs={3}>{Intl.DateTimeFormat("en-GB").format(Date.parse(invoice.invoiceDate))}</Col></Row>
                <Row><Col xs={3} className="invoice-heading">PO Number</Col><Col xs={3}>{invoice.purchaseOrderNumber}</Col></Row>
                <Row><Col xs={3} className="invoice-heading">Customer Reference</Col><Col xs={3}>{invoice.customerReference}</Col></Row>

                <Row className="invoice-table-row"><Col>
                    <Table responsive={true}>
                        <thead>
                        <tr>
                            <th>Quantity</th>
                            <th>Description</th>
                            <th style={{textAlign: "right"}}>Unit Price</th>
                            <th style={{textAlign: "right"}}>Net Amt</th>
                            <th style={{textAlign: "right"}}>Vat %</th>
                            <th style={{textAlign: "right"}}>VAT</th>
                        </tr>
                        </thead>
                        <tbody>
                        {invoice.invoiceLines.map(line =>
                            <tr key={line.invoiceLineId}>
                                <td>{line.quantity}</td>
                                <td>
                                    {line.description.map(textLine =>
                                        <span>{textLine}<br/></span>
                                    )}
                                </td>
                                <td align="right">{new Intl.NumberFormat("en-GB", {
                                    style: "currency",
                                    currency: "GBP"
                                }).format(line.unitPrice)}</td>
                                <td align="right">{new Intl.NumberFormat("en-GB", {
                                    style: "currency",
                                    currency: "GBP"
                                }).format(line.netAmount)}</td>
                                <td align="right">{new Intl.NumberFormat("en-GB", {
                                    style: "percent",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }).format(line.vatPercentage / 100)}</td>
                                <td align="right">{new Intl.NumberFormat("en-GB", {
                                    style: "currency",
                                    currency: "GBP"
                                }).format(line.vat)}</td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </Col></Row>

                <Row><Col xs={{size: 3, offset: 6}} className="invoice-heading">Total Net Amount</Col><Col xs={3} className="right-align">{new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "GBP"
                }).format(invoice.totalNet)}</Col></Row>
                <Row><Col xs={{size: 3, offset: 6}} className="invoice-heading">Carriage Net</Col><Col xs={3} className="right-align">{new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "GBP"
                }).format(invoice.carriageNet)}</Col></Row>
                <Row><Col xs={{size: 3, offset: 6}} className="invoice-heading">Total VAT Amount</Col><Col xs={3} className="right-align">{new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "GBP"
                }).format(invoice.totalVat)}</Col></Row>
                <Row><Col xs={{size: 3, offset: 6}} className="invoice-heading">Invoice Total</Col><Col xs={3} className="right-align">{new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "GBP"
                }).format(invoice.invoiceTotal)}</Col></Row>
                {invoice.trackingLinks && invoice.trackingLinks.length > 0 &&
                <div>
                    <Row className="mt-5">
                        <Col>
                            <h3>Shipments</h3>
                        </Col>
                    </Row>
                    {invoice.trackingLinks.map(link =>
                        <Row key={link.index}>
                            <Col lg={3} sm={4}>
                                {Intl.DateTimeFormat("en-GB").format(Date.parse(link.shipmentDate))}
                            </Col>
                            <Col lg={3} sm={4}>
                                {link.courier}
                            </Col>
                            <Col lg={6} sm={4}>
                                <a href={link.trackingLink}>{link.trackingNumber}</a><br/>
                            </Col>
                        </Row>
                    )}
                </div>
                }
                <Row className="mt-5"><Col>
                    {invoice.pdfLink &&
                    <a href={pdfLink}>Download Invoice PDF</a>
                    }
                </Col></Row>
            </Container>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderInvoice(this.state.invoice);

        return (
            <div>
                <h1>Invoice Detail</h1>
                {contents}
            </div>
        );
    }

    async populateInvoiceInfo() {
        const token = await authService.getAccessToken();
        let data = {};
        if (this.props.match.params.id) {
            const response = await fetch('api/invoices/' + this.props.match.params.id, {
                headers: !token ? {} : {'Authorization': `Bearer ${token}`}
            });
            data = await response.json();
        } else {
            const response = await fetch('api/invoices/legacy/' + this.props.match.params.legacyId, {
                headers: !token ? {} : {'Authorization': `Bearer ${token}`}
            });
            data = await response.json();
        }

        this.setState({invoice: data, loading: false});
    }
}

export default withAITracking(reactPlugin, Invoice);