import React, {Component} from "react";
import {Collapse, Nav, NavItem, NavLink} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';

import authService from './api-authorization/AuthorizeService';
import {withAITracking} from "@microsoft/applicationinsights-react-js";
import {reactPlugin} from "../AppInsights";

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
        }
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.setState({
            showHandpieceSubMenu: this.props.location.pathname.startsWith("/handpieces"),
            showReportsSubMenu: this.props.location.pathname.startsWith("/reports")
        });
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();
        this.setState({
            isAuthenticated
        })
    }

    otherItemClick = () => {
        this.setState({
            showHandpieceSubMenu: false
        });
    };

    onHandpieceItemClick = () => {
        this.setState({
            showHandpieceSubMenu: true
        });
    };

    render() {
        if (this.state.isAuthenticated) {
            return (
                <Nav vertical>
                    <NavItem onClick={this.otherItemClick}>
                        <NavLink tag={Link} to="/invoices">Invoices</NavLink>
                    </NavItem>
                    <NavItem onClick={this.onHandpieceItemClick}>
                        <NavLink tag={Link} to="/handpieces">Handpieces</NavLink>
                    </NavItem>
                    <Collapse isOpen={this.state.showHandpieceSubMenu}>
                        <NavItem className="ml-2">
                            <NavLink tag={Link} to="/handpieces/status/1"><small>Awaiting Courier</small></NavLink>
                        </NavItem>
                        <NavItem className="ml-2">
                            <NavLink tag={Link} to="/handpieces/status/2"><small>In Transit</small></NavLink>
                        </NavItem>
                        <NavItem className="ml-2">
                            <NavLink tag={Link} to="/handpieces/status/3"><small>In Assessment</small></NavLink>
                        </NavItem>
                        <NavItem className="ml-2">
                            <NavLink tag={Link} to="/handpieces/status/4"><small>Waiting for Approval</small></NavLink>
                        </NavItem>
                        <NavItem className="ml-2">
                            <NavLink tag={Link} to="/handpieces/status/5"><small>In Repair</small></NavLink>
                        </NavItem>
                        <NavItem className="ml-2">
                            <NavLink tag={Link} to="/handpieces/status/6"><small>Awaiting Supplier</small></NavLink>
                        </NavItem>
                    </Collapse>
                    <NavItem onClick={this.otherItemClick}>
                        <NavLink tag={Link} to="/requestcourier">Repair Handpieces</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} to="/reports">Reports</NavLink>
                    </NavItem>
                    <Collapse isOpen={this.state.showReportsSubMenu}>
                        <NavItem className="ml-2">
                            <NavLink tag={Link} to="/reports/handpiece-history"><small>Handpiece History</small></NavLink>
                        </NavItem>
                    </Collapse>
                    <NavItem onClick={this.otherItemClick}>
                        <NavLink tag={Link} to="/documents">Documents</NavLink>
                    </NavItem>
                    <NavItem onClick={this.otherItemClick}>
                        <NavLink tag={Link} to="/account">Account</NavLink>
                    </NavItem>
                </Nav>
            )
        } else {
            return null;
        }
    }
}

export default withRouter(withAITracking(reactPlugin, Sidebar));