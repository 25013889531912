import React, {Component} from 'react';
import {Container, Row, Col, Table} from 'reactstrap';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircle} from '@fortawesome/free-solid-svg-icons'
import handpieceService, {statusColours} from "./services/HandpieceService";
import {withAITracking} from "@microsoft/applicationinsights-react-js";
import {reactPlugin} from "../AppInsights";

class Handpieces extends Component {
    static displayName = "Handpiece History";

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        this.populateHandpieceInfo(this.props.match.params.status);
    }

    componentWillReceiveProps(newProps) {
        const newStatus = newProps.match.params.status;
        if (newStatus !== this.props.match.params.status){
            this.populateHandpieceInfo(newStatus);
        }
    }

    static getClass(status) {
        return statusColours[status];
    }

    static renderHandpieceInfo(handpieces) {
        return (
            <Row>
                <Col>
                    <Table>
                        <thead>
                        <tr>
                            <th>Handpiece</th>
                            <th>Serial Number</th>
                            <th>Last Received</th>
                            <th>Repair Detail</th>
                            <th colSpan={2}>Status</th>
                        </tr>
                        </thead>

                       <tbody>
                        {handpieces.map(handpiece =>
                            <tr key={handpiece.id}>
                                <td>{handpiece.make} {handpiece.model}</td>
                                <td>{handpiece.serialNumber}</td>
                                <td>{handpiece.lastBookedIn && Intl.DateTimeFormat("en-GB").format(Date.parse(handpiece.lastBookedIn))}</td>
                                <td>{handpiece.lastRepairDescription}</td>
                                <td className="handpiece-status-col">
                                    <FontAwesomeIcon color={statusColours[handpiece.lastRepairRequestStatus]} icon={faCircle}/>
                                </td>
                                <td><Link to={"/handpiece/" + handpiece.id}>{handpiece.lastRepairRequestStatusText}</Link></td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : Handpieces.renderHandpieceInfo(this.state.filteredHandpieces);

        return (
            <Container fluid>
                <Row><Col>
                    <h1>Handpieces</h1>
                </Col></Row>
                {contents}
            </Container>
        );
    }

    async populateHandpieceInfo(newStatus) {
        let handpieces = this.state.handpieces;
        if (!handpieces) {
            handpieces = await handpieceService.getHandpieces();
            this.setState({handpieces: handpieces});
        }
        let filteredHandpieces = handpieces;
        if (newStatus) {
            filteredHandpieces = handpieces.filter(x => x.lastRepairRequestStatus === parseInt(newStatus));
        }
        this.setState({filteredHandpieces: filteredHandpieces, loading: false});
    }
}

export default withAITracking(reactPlugin, Handpieces);