import React, {Component} from 'react';
import {Table, Pagination, PaginationItem, PaginationLink} from 'reactstrap';
import authService from './api-authorization/AuthorizeService'
import querystring from "query-string";
import {Link} from "react-router-dom";
import {withAITracking} from "@microsoft/applicationinsights-react-js";
import {reactPlugin} from "../AppInsights";

class Invoices extends Component {
    static displayName = "Invoice History";

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            rowsPerPage: 10,
            currentPage: 1
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleNextClick = this.handleNextClick.bind(this);
        this.handlePrevClick = this.handlePrevClick.bind(this);
    }

    componentDidMount() {
        this.populateInvoiceInfo();
    }

    handleClick(e, id) {
        e.preventDefault();
        this.setState({
            currentPage: Number(id)
        });
        this.populateInvoiceInfo();
    }

    handleNextClick(e) {
        e.preventDefault();
        this.setState({
            currentPage: this.state.currentPage + 1
        });
        this.populateInvoiceInfo();
    }

    handlePrevClick(e) {
        e.preventDefault();
        this.setState({
            currentPage: this.state.currentPage - 1
        });
        this.populateInvoiceInfo();
    }

    renderInvoices(state) {
        let invoices = state.invoices;
        let totalPages = 1;
        if (invoices.length > 0) {
            totalPages = Math.ceil(invoices[0].totalRows / state.rowsPerPage)
        }

        let startPage = state.currentPage - 4;
        if (startPage < 1) {
            startPage = 1;
        }

        let endPage = startPage + 8;
        if (endPage > totalPages) {
            endPage = totalPages;
            startPage = totalPages - 8;
            if (startPage < 1) {
                startPage = 1;
            }
        }

        let pages = [];
        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        return (
            <div>
                <Table responsive={true}>
                    <thead>
                    <tr>
                        <th>Invoice No</th>
                        <th>Date</th>
                        <th style={{textAlign: "right"}}>Net</th>
                        <th style={{textAlign: "right"}}>Vat</th>
                        <th style={{textAlign: "right"}}>Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    {invoices.map(invoice =>
                        <tr key={invoice.invoiceId}>
                            <td>
                                {invoice.isLegacy &&
                                    <Link to={"/invoice/l/" + invoice.invoiceId}>{invoice.invoiceNumber}</Link>
                                }
                                {!invoice.isLegacy &&
                                    <Link to={"/invoice/" + invoice.invoiceId}>{invoice.invoiceNumber}</Link>
                                }
                            </td>
                            <td>{invoice.invoiceDate}</td>
                            <td align="right">{new Intl.NumberFormat("en-GB", {
                                style: "currency",
                                currency: "GBP"
                            }).format(invoice.value)}</td>
                            <td align="right">{new Intl.NumberFormat("en-GB", {
                                style: "currency",
                                currency: "GBP"
                            }).format(invoice.vat)}</td>
                            <td align="right">{new Intl.NumberFormat("en-GB", {
                                style: "currency",
                                currency: "GBP"
                            }).format(invoice.total)}</td>
                        </tr>
                    )}
                    </tbody>
                </Table>
                {(totalPages > 1) &&
                <Pagination>
                    <PaginationItem disabled={(state.currentPage === 1)}>
                        <PaginationLink previous href="#" onClick={this.handlePrevClick}/>
                    </PaginationItem>
                    {pages.map(page =>
                        <PaginationItem active={page === state.currentPage} key={page}>
                            <PaginationLink href="#" id={page} onClick={e => this.handleClick(e, page)}>
                                {page}
                            </PaginationLink>
                        </PaginationItem>
                    )}
                    <PaginationItem disabled={(state.currentPage === totalPages)}>
                        <PaginationLink next href="#" onClick={this.handleNextClick}/>
                    </PaginationItem>
                </Pagination>
                }
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderInvoices(this.state);

        return (
            <div>
                <h1>Invoice History</h1>
                {contents}
            </div>
        );
    }

    async populateInvoiceInfo() {
        const token = await authService.getAccessToken();

        const queryParams = {rowsPerPage: this.state.rowsPerPage, currentPage: this.state.currentPage};
        const response = await fetch('api/invoices?' + querystring.stringify(queryParams), {
            headers: !token ? {} : {'Authorization': `Bearer ${token}`}
        });
        const data = await response.json();
        this.setState({invoices: data, loading: false});
    }
}

export default withAITracking(reactPlugin, Invoices);