import {ACTIVE_QUOTES, DISMISS_QUOTES} from "../actionTypes";

const initialState = {
    quotes: 0,
    isDismissed: false
};

export default function(state = initialState, action) {
    switch(action.type) {
        case ACTIVE_QUOTES: {
            return {
                ...state,
                quotes: action.payload
            };
        }
        case DISMISS_QUOTES: {
            return {
                ...state,
                isDismissed: action.payload
            }
        }
        default:
            return state;
    }
}