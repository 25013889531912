import React, {Component} from "react";
import {Col, Container, Row, Table} from "reactstrap";
import companyInfoService from "./services/CompanyInfoService";
import accountService from "./services/AccountService";
import {withAITracking} from "@microsoft/applicationinsights-react-js";
import {reactPlugin} from "../AppInsights";

class RepairPrintForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            printFormLoading: true,
            selectedHandpieces: [{make: "Test make", model: "Test model", serialNumber: "SN123123123"}],
            selectedNewHandpieces: [{make: "Test make", model: "Test model", serialNumber: "SN123123123"}],
            companyInfo: null
        };
    }

    componentDidMount() {
        window.addEventListener('message', this.handleMessage);
        window.parent.postMessage({action: 'repair-print-form-loaded'});
        this.populateState();
    }

    async populateState() {
        const companyInfo = await companyInfoService.getCompanyInfo();
        const accountInfo = await accountService.getAccountInfo();
        this.setState({
            companyInfo: companyInfo,
            accountInfo: accountInfo
        });

    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handleMessage);
    }

    handleMessage = (event) => {
        if (event.data.action === 'repair-print-form-data') {
            this.setState({
                printFormLoading: false,
                selectedHandpieces: event.data.selectedHandpieces,
                selectedNewHandpieces: event.data.selectedNewHandpieces
            });
            window.parent.postMessage({action: 'repair-print-form-loaded-2'});
        }
    };

    formatAddress() {
        let address = "";

        if (this.state.accountInfo.addressLine1) {
            address = address + this.state.accountInfo.addressLine1 + ", "
        }
        if (this.state.accountInfo.addressLine2) {
            address = address + this.state.accountInfo.addressLine2 + ", "
        }
        if (this.state.accountInfo.town) {
            address = address + this.state.accountInfo.town + ", "
        }
        if (this.state.accountInfo.county) {
            address = address + this.state.accountInfo.county
        }
        if (address.substring(address.length - 2, 2) === ", ") {
            address = address.substring(0, address.length - 2);
        }
        return address;
    }

    render() {
        return (
            <Container className="print mt-4">
                <Row>
                    <Col xs={8}>
                        <img height="160px" src="/images/mc-repairs-logo.png" alt="MC Repairs"/>
                    </Col>
                    <Col xs={4} className="text-right">
                        {this.state.companyInfo && this.state.companyInfo.address.map((line, i) =>
                            <span key={i}>{line}<br/></span>
                        )}
                        t: {this.state.companyInfo && this.state.companyInfo.phone}<br/>
                        e: {this.state.companyInfo && this.state.companyInfo.email}<br/>
                        w: {this.state.companyInfo && this.state.companyInfo.website}<br/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h1>Repairs Order Form</h1>
                    </Col>
                </Row>
                {this.state.accountInfo &&
                <Row className="print-border">
                    <Col xs={12} className="print-header">Customer Details</Col>
                    <Col xs={2}>Name:</Col>
                    <Col xs={8}>{this.state.accountInfo.name} c/o {this.state.accountInfo.practice}</Col>
                    <Col xs={2} className="text-right">{this.state.accountInfo.reference}</Col>
                    <Col xs={2}>Address:</Col>
                    <Col xs={10}>{this.formatAddress()}</Col>
                    <Col xs={2}>Post Code:</Col>
                    <Col xs={10}>{this.state.accountInfo.postCode}</Col>
                    <Col xs={2}>Telephone:</Col>
                    <Col xs={10}>{this.state.accountInfo.phoneNumber}</Col>
                    <Col xs={2}>Email:</Col>
                    <Col xs={10}>{this.state.accountInfo.email}</Col>
                </Row>
                }

                {this.state.selectedHandpieces.length + this.state.selectedNewHandpieces.length > 0 &&

                <Row className="print-border mt-3 print-big-line-height">
                    <Col xs={12} className="print-header" style={{marginBottom: "0"}}>Handpiece Details</Col>

                    <Table className="print-handpiece-table">
                        <thead>
                        <tr>
                            <th/>
                            <th>Make</th>
                            <th>Model</th>
                            <th>Serial No.</th>
                            <th style={{width: "33%"}}>Additional Notes</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.selectedHandpieces.map((h, i) =>
                            <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{h.make}</td>
                                <td>{h.model}</td>
                                <td>{h.serialNumber}</td>
                                <td/>
                            </tr>
                        )}
                        {this.state.selectedNewHandpieces.map((h, i) =>
                            <tr key={i}>
                                <td>{i + 1 + this.state.selectedHandpieces.length}</td>
                                <td>{h.make}</td>
                                <td>{h.model}</td>
                                <td>{h.serialNumber}</td>
                                <td/>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </Row>
                }

                <Row className="print-border mt-3 print-big-line-height">
                    <Col xs={12} className="print-header">Quote details will be emailed to you via our online account system. If you would prefer a phone or text quotation or for us to just continue
                        with the handpiece repair, please complete below</Col>

                    <Col xs={8}>
                        <div>Phone: <div className="print-check-box"/></div>
                        <div>Text: <div className="print-check-box"/></div>
                        <div className="print-dotted-line">Name: <span className="dot"/></div>
                        <div className="print-dotted-line">Signed: <span className="dot"/></div>
                        <div className="print-dotted-line">Date: <span className="dot"/></div>
                    </Col>
                    <Col xs={4} className="print-check-box-pane">
                        <strong>Automatic Repairs</strong><br/>
                        <small>(excludes VAT & delivery)</small><br/>
                        <div className="print-check-box-big"/>
                        £35.95<br/>
                        <div className="print-check-box-big"/>
                        £65.95<br/>
                        <div className="print-check-box-big"/>
                        Under £120.00<br/>
                    </Col>
                </Row>

                <Row className="print-border mt-3 print-big-line-height">
                    <Col xs={12} className="print-header">Decontamination Declaration</Col>

                    <Col xs={10} style={{width: "75%"}}>
                        <Container className="pl-0 pr-0 flex">
                            <Row>
                                <Col xs={10}>
                                    <div className="print-dotted-line">Signed by Dentist: <span className="dot"/></div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={5}>
                                    <div className="print-dotted-line">Print Name: <span className="dot"/></div>
                                </Col>
                                <Col xs={5}>
                                    <div className="print-dotted-line">Date: <span className="dot"/></div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col xs={2} className="text-right">
                        <img src="/images/ISO9001.gif" alt="MC Repairs"/>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default withAITracking(reactPlugin, RepairPrintForm);