import React, {Component} from 'react';
import authService from '../api-authorization/AuthorizeService'
import {Button, Col, Row, Table} from "reactstrap";
import {withAITracking} from "@microsoft/applicationinsights-react-js";
import {reactPlugin} from "../../AppInsights";

class HandpieceHistory extends Component {
    static displayName = "Handpiece History Report";

    constructor(props) {
        super(props);
        this.state = {
            reportLines: []
        }
    }

    componentDidMount() {
        this.populateDocuments();
    }

    async populateDocuments() {
        const token = await authService.getAccessToken();
        const response = await fetch('api/reports/handpiece-history', {
            headers: !token ? {} : {'Authorization': `Bearer ${token}`}
        });
        const data = await response.json();
        this.setState({reportLines: data});
    }

    async onClick(e) {
        e.preventDefault();
        const token = await authService.getAccessToken();
        const response = await fetch('api/reports/handpiece-history/download', {
            headers: !token ? {} : {'Authorization': `Bearer ${token}`}
        });
        const data = await response.blob();
        const url = window.URL.createObjectURL(new Blob([data], {type: "text/csv"}));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', "handpiecehistory.csv");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    render() {
        return (
            <div>
                <h1>Handpiece History Report</h1>
                <Row>
                    <Col xs={12}>
                        <Table>
                            <thead>
                            <tr>
                                <th>Invoice Date</th>
                                <th>Handpiece</th>
                                <th>Repair Instructions</th>
                                <th>Invoice Number</th>
                                <th>PO Number</th>
                                <th>Accepted By</th>
                                <th>Qty</th>
                                <th>Charge</th>
                                <th>Vat</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>

                            {this.state.reportLines.map(line =>
                                <tr>
                                    <td>{Intl.DateTimeFormat("en-GB").format(Date.parse(line.invoiceDate))}</td>
                                    <td>{line.makeName + " " + line.modelName + " (" + line.serialNumber + ")"}</td>
                                    <td>{line.instructions}</td>
                                    <td>{line.sageOrderNumber}</td>
                                    <td>{line.purchaseOrderNumber}</td>
                                    <td>{line.acceptedBy}</td>
                                    <td>{line.quantity}</td>
                                    <td>{new Intl.NumberFormat("en-GB", {
                                        style: "currency",
                                        currency: "GBP"
                                    }).format(line.charge)}</td>
                                    <td>{new Intl.NumberFormat("en-GB", {
                                        style: "currency",
                                        currency: "GBP"
                                    }).format(line.vatTotal)}</td>
                                    <td>{line.description}</td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </Col>
                    <Col xs={12}>
                        <Button color="primary" onClick={(e) => this.onClick(e)}>Download</Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withAITracking(reactPlugin, HandpieceHistory);