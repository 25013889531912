import './custom.css'

import React, {Component} from 'react';
import {Route, Switch} from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import Account from './components/Account';
import Handpieces from './components/Handpieces';
import Invoices from "./components/Invoices";
import Invoice from "./components/Invoice";
import RequestCourier from "./components/RequestCourier";
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import {ApplicationPaths} from './components/api-authorization/ApiAuthorizationConstants';
import Handpiece from "./components/Handpiece";
import Documents from "./components/Documents";
import Reports from "./components/Reports";
import HandpieceHistory from "./components/reports/HandpieceHistory";
import RepairPrintForm from "./components/RepairPrintForm";

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Switch>
                    <Route exact path='/' component={Home}/>
                    <AuthorizeRoute path='/account' component={Account}/>
                    <AuthorizeRoute path='/handpieces/status/:status' component={Handpieces}/>
                    <AuthorizeRoute path='/handpieces' component={Handpieces}/>
                    <AuthorizeRoute path='/handpiece/:id' component={Handpiece}/>
                    <AuthorizeRoute path='/requestcourier' component={RequestCourier}/>
                    <AuthorizeRoute path='/invoices' component={Invoices}/>
                    <AuthorizeRoute path='/invoice/l/:legacyId' component={Invoice}/>
                    <AuthorizeRoute path='/invoice/:id' component={Invoice}/>
                    <AuthorizeRoute path='/documents/' component={Documents}/>
                    <AuthorizeRoute path='/reports/handpiece-history' component={HandpieceHistory}/>
                    <AuthorizeRoute path='/reports/' component={Reports}/>
                    <AuthorizeRoute path='/repair-print-form' component={RepairPrintForm}/>

                    <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes}/>
                </Switch>
            </Layout>
        );
    }
}
