import React, {Component} from "react";
import {Col, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {withAITracking} from "@microsoft/applicationinsights-react-js";
import {reactPlugin} from "../AppInsights";

class Reports extends Component {
    static displayName = "Documents";

    render() {
        return (
            <div>
                <h1>Reports</h1>
                <Row>
                    <Col xs={12}>
                        <Link to="/reports/handpiece-history">Handpiece History</Link>
                    </Col>
                </Row>
            </div>
        );

    }
}

export default withAITracking(reactPlugin, Reports);