import {SET_REPAIR_FORM_DATA} from "../actionTypes";

const initialState = {
    totalHandpieces: 0,
    selectedHandpieces: [],
    selectedNewHandpieces: []
};

export default function(state = initialState, action) {
    switch(action.type) {
        case SET_REPAIR_FORM_DATA: {
            console.log("Setting repair form data: " + action.payload.selectedHandpieces.length);
            return {
                ...state,
                totalHandpieces: action.payload.selectedHandpieces.length,
                selectedHandpieces: [...action.payload.selectedHandpieces],
                selectedNewHandpieces: [...action.payload.selectedNewHandpieces]
            };
        }
        default:
            return state;
    }
}