import authService from "../api-authorization/AuthorizeService";

export class AccountService {
    async getAccountInfo() {
        const token = await authService.getAccessToken();
        const response = await fetch('api/account', {
            headers: !token ? {} : {'Authorization': `Bearer ${token}`}
        });
        return await response.json();
    }
}

const accountService = new AccountService();

export default accountService;