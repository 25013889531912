import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {ApplicationPaths} from "./api-authorization/ApiAuthorizationConstants";
import authService from "./api-authorization/AuthorizeService";
import {Col, Container, Row} from "reactstrap";
import {withAITracking} from "@microsoft/applicationinsights-react-js";
import {reactPlugin} from "../AppInsights";

class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false
        }
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();
        this.setState({
            isAuthenticated
        })
    }

    render() {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <h1>Welcome to MC Repairs Online!</h1>
                        {!this.state.isAuthenticated &&
                        <div>
                            <p>Login to your online account <Link to={ApplicationPaths.Login}>here</Link></p>
                            <p> To set up your online account, give MC Repairs a call on 01253 404774.</p>
                        </div>
                        }
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withAITracking(reactPlugin, Home);