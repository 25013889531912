import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory({ basename: '' });

if (!browserHistory) {
    throw new Error('Could not initialize Telemetry Service');
}

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: 'd4991a7a-e2f1-4136-8468-652281007166', //prod
        //instrumentationKey: 'de967b7d-23d2-47f8-acd3-fd8cff68a458', //test
        maxBatchInterval: 0,
        disableFetchTracking: false,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();

export { reactPlugin, appInsights };