import React, {Component} from 'react';
import {Container, Col, Row, Alert} from 'reactstrap';
import NavMenu from './NavMenu';
import Sidebar from './Sidebar';
import './Layout.css';
import authService from "./api-authorization/AuthorizeService";
import handpieceService from "./services/HandpieceService";
import {Link} from "react-router-dom";
import {setActiveQuotes, dismissQuote} from "../redux/actions";
import {connect} from "react-redux";
import companyInfoService from "./services/CompanyInfoService";
import {withAITracking} from "@microsoft/applicationinsights-react-js";
import {reactPlugin} from "../AppInsights";

class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            companyInfo: null
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
        this._isMounted = false;
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();
        this.setState({companyInfo: await companyInfoService.getCompanyInfo()});
        let awaitingQuote = 0;
        if (isAuthenticated) {
            awaitingQuote =  await handpieceService.getCountAwaitingQuote();
        }
        this.props.dispatch(setActiveQuotes(awaitingQuote));
        if (this._isMounted) {
            this.setState({
                isAuthenticated
            });
        }
    }

    onDismiss = () => {
        this.props.dispatch(dismissQuote(true));
    };

    render() {
        return (
            <div>
                <NavMenu/>
                <Container style={{minHeight: '200px'}}>
                    {this.state.isAuthenticated &&
                    <Row className="main-row">
                        <Col md={3} xl={2} className="d-none d-xl-block no-print">
                            <Sidebar/>
                        </Col>

                        <Col xl={10}>
                            <Alert className="no-print" color="info" isOpen={!this.props.isDismissed && this.props.quotes > 0} toggle={this.onDismiss}>You have {this.props.quotes} items pending approval for repair. Click <Link to={"/handpieces/status/4"}>here</Link> to view.</Alert>
                            {this.props.children}
                        </Col>
                    </Row>
                    }
                    {!this.state.isAuthenticated &&
                    <Row className="main-row">
                        <Col>
                            {this.props.children}
                        </Col>
                    </Row>
                    }
                </Container>
                <div className="footer no-print">
                    <Container>
                        <Row>
                            <Col className="footer-col" md={4}>
                                <h3>FOLLOW US</h3>
                                <a href="https://www.facebook.com/MCRepairsLtd" className="social-icon"><i className="fa fa-facebook"/></a>
                                <a href="https://twitter.com/mcrepairsltd" className="social-icon"><i className="fa fa-twitter"/></a>
                                <a href="https://www.linkedin.com/company/mc-repairs-ltd/" className="social-icon"><i className="fa fa-linkedin"/></a>
                                <a href="https://www.instagram.com/mcrepairsltd/" className="social-icon"><i className="fa fa-instagram"/></a>
                                <a href="https://www.youtube.com/watch?v=RhyjbX7jKOA" className="social-icon"><i className="fa fa-youtube"/></a>
                            </Col>
                            <Col className="footer-col" md={4}>
                                <h3>CONTACT US</h3>
                                <p>
                                    {this.state.companyInfo && this.state.companyInfo.address.map((line, i) =>
                                        <span key={i}>{line}<br/></span>
                                    )}
                                    {this.state.companyInfo && this.state.companyInfo.phone}<br/>
                                    <a href={"mailto:" + (this.state.companyInfo && this.state.companyInfo.email)}>{this.state.companyInfo && this.state.companyInfo.email}</a>
                                </p>
                            </Col>
                            <Col className="footer-col" md={4}>
                                <h3>FAQ'S</h3>
                                <p>
                                    <a href="https://mcrepairs.co.uk/cookie-policy/">Cookie Policy</a><br/>
                                    <a href="https://mcrepairs.co.uk/loyalty-card-terms-and-conditions/">Loyalty Card -
                                        Terms and Conditions</a><br/>
                                    <a href="https://mcrepairs.co.uk/privacy-policy/">Privacy policy</a><br/>
                                    <a href="https://mcrepairs.co.uk/prize-draw-terms-and-conditions-2018/">Prize Draw -
                                        Terms and Conditions 2018</a><br/>
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        quotes: state.activeQuotes.quotes,
        isDismissed: state.activeQuotes.isDismissed
    }
}

export default connect(
    mapStateToProps,
    null
)(withAITracking(reactPlugin, Layout));