import React, {Component} from 'react';
import {Container, Row, Col, Card, CardBody, CardText, CardHeader, Button, Alert} from "reactstrap";
import handpieceService, {statusColours} from "./services/HandpieceService";
import {faCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from "react-redux";
import {setActiveQuotes} from "../redux/actions";
import {Link} from "react-router-dom";
import companyInfoService from "./services/CompanyInfoService";
import {withAITracking} from "@microsoft/applicationinsights-react-js";
import {reactPlugin} from "../AppInsights";

class Handpiece extends Component {
    static displayName = "Handpiece Repair Detail"

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            acceptQuoteButtonDisabled: false
        };
    }

    async componentDidMount() {
        this.setState({companyInfo: await companyInfoService.getCompanyInfo()});
        this.populateHandpieceInfo();
    }

    acceptQuote = async () => {
        this.setState({acceptQuoteButtonDisabled: true});
        const result = await handpieceService.acceptQuote(this.props.match.params.id);
        if (result === '') {
            this.setState({requestErrored: false, errorMessage: '', requestSuccessful: true});
        } else {
            this.setState({requestErrored: true, errorMessage: result, requestSuccessful: false, acceptQuoteButtonDisabled: false});
        }
        const awaitingQuote = await handpieceService.getCountAwaitingQuote();
        this.props.dispatch(setActiveQuotes(awaitingQuote));
    };

    renderHandpieceInfo = () => {
        return (
            <div>
                <Row className="mb-3">
                    <Col sm={12}>
                        Handpiece: {this.state.handpiece.make} {this.state.handpiece.model} <br/>
                        Serial: {this.state.handpiece.serialNumber}
                    </Col>
                </Row>
                <Row>
                    {this.state.handpiece.statusChanges.map(statusChange =>
                        <Col sm={12} key={statusChange.id} className="mb-3">
                            <Card>
                                <CardHeader>
                                    <FontAwesomeIcon color={statusColours[statusChange.toStatus]} icon={faCircle}/> {statusChange.toStatusText}
                                </CardHeader>
                                <CardBody>
                                    <CardText>
                                        On: {Intl.DateTimeFormat("en-GB").format(Date.parse(statusChange.date))} by: {statusChange.user}<br/>
                                        Notes: {statusChange.notes}
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    )}
                    {this.state.handpiece.invoiceId &&
                    <Col>
                        <Link to={"/invoice/" + this.state.handpiece.invoiceId}>View Invoice</Link>
                    </Col>
                    }
                    {this.state.handpiece.statusChanges.length === 0 &&
                    <Col>
                        No history available for this Handpiece
                    </Col>
                    }

                    {this.state.handpiece.lastRepairRequestStatus === 4 && this.state.companyInfo &&
                    <Col sm={12} className="mb-3">
                        <Alert color="warning">If you wish to discuss the quote or any alternative options further, please call MC Repairs on {this.state.companyInfo.phone}.</Alert>
                    </Col>
                    }
                    {this.state.handpiece.lastRepairRequestStatus === 4 &&
                    <Col sm={12} className="mb-3">
                        <Button color="primary" disabled={this.state.acceptQuoteButtonDisabled} onClick={() => this.acceptQuote()}>Accept Quote</Button>
                    </Col>
                    }
                    {this.state.requestSuccessful &&
                    <Col sm={12} className="mb-3">
                        <Alert color="success">Quote accepted. Your handpiece is now being repaired - we will contact you when the repairs are finished and it's on its way back to you.</Alert>
                    </Col>
                    }
                    {this.state.requestErrored &&
                    <Col sm={12} className="mb-3">
                        <Alert color="error">{this.state.errorMessage}</Alert>
                    </Col>
                    }
                </Row>
            </div>
        );
    };

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderHandpieceInfo();

        return (
            <Container fluid>
                <Row>
                    <Col>
                        <h1>{Handpiece.displayName}</h1>
                    </Col>
                </Row>
                {contents}
            </Container>
        );
    }

    async populateHandpieceInfo() {
        this.setState({handpiece: await handpieceService.getHandpieceDetail(this.props.match.params.id), loading: false});
    }
}

export default connect(
    null,
    null
)(withAITracking(reactPlugin, Handpiece));