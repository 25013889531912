import React, {Component} from 'react';
import authService from './api-authorization/AuthorizeService'
import {Alert, Button, Form, FormGroup, Input, Label} from 'reactstrap';
import accountService from "./services/AccountService";
import {withAITracking} from "@microsoft/applicationinsights-react-js";
import {reactPlugin} from "../AppInsights";

class Account extends Component {
    static displayName = "Account Details";

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            preferenceButtonDisabled: false,
            preferenceSubmitErrored: false,
            preferenceSubmitSuccessful: false,
            errorMessage: '',
            sendQuoteEmails: false,
            sendInvoiceEmails: false,
            sendOfferEmails: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.populateAccountInfo();
    }

    renderAddressLine = (addressLine) => {
        if (addressLine) {
            return (<span>{addressLine}<br/></span>)
        }
    };

    renderAccountInfo = (accountInfo) => {
        return (
            <div>
                <h4>Login Details:</h4>
                <p>
                    Name: {accountInfo.name}<br/>
                    Email: {accountInfo.email}
                </p>

                <h4>Practice Info:</h4>
                <p>
                    {this.renderAddressLine(accountInfo.practice)}
                    {this.renderAddressLine(accountInfo.addressLine1)}
                    {this.renderAddressLine(accountInfo.addressLine2)}
                    {this.renderAddressLine(accountInfo.town)}
                    {this.renderAddressLine(accountInfo.county)}
                    {this.renderAddressLine(accountInfo.country)}
                    {this.renderAddressLine(accountInfo.postCode)}
                    {accountInfo.phoneNumber}
                </p>

                <Alert color="warning">Please note we are unable to change account details online, please call MC
                    Repairs on 01253 404774 if any details are incorrect.</Alert>

                <a href="/Identity/Account/Manage/ChangePassword">Change Password</a>

                <Form onSubmit={this.handleSubmit}>
                    <h4 className="mt-5">Email Preferences:</h4>
                    <FormGroup className="ml-4">
                        <Input type="checkbox" name="sendquoteemails" id="send-quote-emails" checked={this.state.sendQuoteEmails} onChange={this.handleQuote}/>
                        <Label for="send-quote-emails">Send me progress updates on my handpiece repairs</Label>
                    </FormGroup>
                    <FormGroup className="ml-4">
                        <Input type="checkbox" name="sendinvoiceemails" id="send-invoice-emails" checked={this.state.sendInvoiceEmails} onChange={this.handleInvoice}/>
                        <Label for="send-invoice-emails">Send me copies of repair invoices by email</Label>
                    </FormGroup>
                    <FormGroup className="ml-4">
                        <Input type="checkbox" name="sendofferemails" id="send-offer-emails" checked={this.state.sendOfferEmails} onChange={this.handleOffer}/>
                        <Label for="send-offer-emails">Keep me updated with offers and updates</Label>
                    </FormGroup>
                    {this.state.preferenceSubmitErrored &&
                    <Alert color="danger">{this.state.errorMessage}</Alert>
                    }
                    {this.state.preferenceSubmitSuccessful &&
                    <Alert color="success">Preferences Updated</Alert>
                    }
                    <Button type="submit" color="primary" disabled={this.state.preferenceButtonDisabled}>Update Preferences</Button>
                </Form>

            </div>
        );
    };

    handleOffer = () => {
        this.setState({
            sendOfferEmails: !this.state.sendOfferEmails
        });
    };

    handleInvoice = () => {
        this.setState({
            sendInvoiceEmails: !this.state.sendInvoiceEmails
        });
    };

    handleQuote = () => {
        this.setState({
            sendQuotesEmails: !this.state.sendQuotesEmails
        });
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        const token = await authService.getAccessToken();
        this.setState({preferenceButtonDisabled: true});
        const data = JSON.stringify({
            sendOfferEmails: this.state.sendOfferEmails,
            sendInvoiceEmails: this.state.sendInvoiceEmails,
            sendQuoteEmails: this.state.sendQuoteEmails
        });
        const response = await fetch('api/account', {
            method: 'POST',
            body: data,
            headers: !token ? {} : {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.ok) {
            this.setState({preferenceSubmitErrored: false, errorMessage: '', preferenceSubmitSuccessful: true});
        } else {
            const data = await response.json();
            this.setState({preferenceSubmitErrored: true, errorMessage: data.message, preferenceButtonDisabled: false});
        }
    };

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderAccountInfo(this.state.accountInfo);

        return (
            <div>
                <h1>Account Details</h1>
                {contents}
            </div>
        );
    }

    async populateAccountInfo() {
        const accountInfo = await accountService.getAccountInfo();
        this.setState({
            accountInfo: accountInfo,
            loading: false,
            sendQuoteEmails: accountInfo.sendQuoteEmails,
            sendInvoiceEmails: accountInfo.sendInvoiceEmails,
            sendOfferEmails: accountInfo.sendOfferEmails
        });
    }
}

export default withAITracking(reactPlugin, Account);